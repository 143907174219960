import React, { useState, useContext, useEffect } from 'react';
import './AdminDashboard.css';
import AppContext from '../utils/AppContext';
import axios from 'axios';
import { MdCloudUpload } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import no_profile from '../../assets/image/no_profile_picture.svg';
import StaticNavBar from "../StaticNavBar/StaticNavBar";
import Navbar from "../Navbar/Navbar";
import DashboardNavbar from "../Dashboard_Navbar/Dashboard_Navbar";
import CreateLender from './CreateLender';

function LenderDetails() {
    const [isLoading, setIsLoading] = useState(true);
    const [lenders, setLenders] = useState([]);
    const [selectedLender, setSelectedLender] = useState(null);
    const navigate = useNavigate();
    let {backendRoot} = useContext(AppContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const accessToken = JSON.parse(localStorage.getItem('authTokens')).access;
                const response = await axios.get(`${backendRoot}/getlenders/`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setLenders(response.data);
                setIsLoading(false);
            } 
            catch (error) {
                console.error('Error fetching lender data:', error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleCreateUser = (lender) => {
        setSelectedLender(lender);
        // navigate(`/create-lender/${lender.id}`, {state: { setLenders: lender } });
        navigate(`/create-lender/${lender.id}`, {state: { lender: lender } });

    };

    return (
        <div className="Homepage_master_div">
            <StaticNavBar />
            <div className="navbar-for-dashboard">
                <Navbar showApplyNowButton={false} isDashboard={true} />
            </div>
            <DashboardNavbar />
            <div className="dashboard_master_div">
                <div className="admin-dashboard">
                    <h1>Lender Details</h1>
                    {isLoading ? (
                        <div className="loader"></div>
                    ) : (
                        <>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Telephone</th>
                                        <th>Email</th>
                                        <th>Contact Person</th>
                                        <th>Address</th>
                                        <th>Partnership Types</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lenders.map(lender => (
                                        <tr key={lender.id}>
                                            <td>{lender.name}</td>
                                            <td>{lender.telephone}</td>
                                            <td>{lender.email}</td>
                                            <td>{lender.contact_person}</td>
                                            <td>{lender.address}</td>
                                            <td>{lender.partnership_types}</td>
                                            <td>
                                                <button onClick={() => handleCreateUser(lender)}>Create User</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {selectedLender && <CreateLender lender={selectedLender} />}
                        </>
                    )}
                </div>
            </div>
        </div>

    );
}

export default LenderDetails;
