import React, { useContext, useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import StaticNavBar from "../StaticNavBar/StaticNavBar";
import AppContext from "../utils/AppContext";
import { useNavigate } from "react-router-dom";
import "./Lender_Partner.css"
import axios from "axios";


const LenderPartner = () => {

    let {backendRoot} = useContext(AppContext);

    const [formData, setFormData] = useState({
        name: "",
        telephone: "",
        email: "",
        contact_person: "",
        address: "",
        partnership_types: "Unsecured Loan", // default value
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${backendRoot}/lender/apply/`, formData);
            alert("Form submitted successfully!");
            // Optionally, you can reset the form after successful submission
            setFormData({
                name: "",
                telephone: "",
                email: "",
                contact_person: "",
                address: "",
                partnership_types: "Unsecured Loan",
            });
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("Error submitting form. Please try again.");
        }
    };


return (
    <div className="Homepage_master_div">
        <div className="static-nav-bar">
            <StaticNavBar />
        </div>
        <div className="login_background_picture"></div>
        <div className="navbar_homepage">
            <Navbar showApplyNowButton={true} isDashboard={false}  />
        </div>



        <div className="dashboard_master_div">
        <div className="user_profile_master_div">
        <div className="user_profile_details">
                    <div className="profile_detail_div">
                    <h2>Send us a message</h2>
                    
                    
                    <form id="inputform" onSubmit={handleSubmit}>
                        <div className="input_wrapper">
                        <input className="lender_form_input" type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Name" />
                                    <input className="lender_form_input" type="tel" name="telephone" value={formData.telephone} onChange={handleChange} placeholder="TelePhone" />
                                    <input className="lender_form_input" type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email" />
                                    <input className="lender_form_input" type="text" name="contact_person" value={formData.contact_person} onChange={handleChange} placeholder="Contact Person(Agent Name)" />
                                    <input className="lender_form_input" type="text" name="address" value={formData.address} onChange={handleChange} placeholder="Address" />

                            <div>
                                <span>Partnership Types</span>

                                <select
                                    name="partnership_types"
                                    value={formData.partnership_types}
                                    onChange={handleChange}
                                >
                                <option value="Unsecured Loan">Unsecured Loan</option>
                                <option value="Property Backed Loan">Property Backed Loan</option>
                                <option value="Merchant Cash Advance">Merchant Cash Advance</option>
                                <option value="Invoice Discounting">Invoice Discounting</option>
                                <option value="Growth Finance">Growth Finance</option>
                                <option value="Business Acquisition">Business Acquisition</option>
                                <option value="Vehicle Finance">Vehicle Finance</option>
                                <option value="Equipment Finance">Equipment Finance</option>
                                <option value="Bridging Finance">Bridging Finance</option> 

                                </select>
                            </div>

                        </div>
                        <div className="lender_submit_div">
                        <button type="submit" className="lender_submit_button">Submit</button>
                        </div>
                    

                    </form>
                    

                    </div>
                    <div className="border_master_div">
                    <div className="user_profile_border_line"></div>
                    </div>
                    <div className="user_profile_edit_div">
                    <h2>Contact Us:</h2>
                            
                    {/* <div className="text_input">
                        <span>Company Name</span>
                        <input
                        type="text"
                        name="username"
                        value={updatedUserDetail.username}
                        onChange={handleInputChange}
                        />
                    </div> */}
                    {/* <div className="text_input">
                        <span>First Name</span>
                        <input
                        type="text"
                        name="first_name"
                        value={updatedUserDetail.first_name}
                        onChange={handleInputChange}
                        />
                    </div>
                    <div className="text_input">
                        <span>Last Name</span>
                        <input
                        type="text"
                        name="last_name"
                        value={updatedUserDetail.last_name}
                        onChange={handleInputChange}
                        />
                    </div>
                    <div className="text_input">
                        <span>Email</span>
                        <input
                        type="text"
                        name="email"
                        value={updatedUserDetail.email}
                        onChange={handleInputChange}
                        />
                    </div>
                    <div className="text_input">
                        <span>Phone Number</span>
                        <input
                        type="text"
                        name="phone_number"
                        value={updatedUserDetail.phone_number}
                        onChange={handleInputChange}
                        />
                    </div>
                    <div className="text_input">
                        <span>How many years in Business</span>
                        <input
                        type="number"
                        name="years_in_business"
                        value={updatedUserDetail.years_in_business}
                        onChange={handleInputChange}
                        />
                    </div>
                    <div className="text_input">
                        <span>PO Value</span>
                        <input
                        type="number"
                        name="po_value"
                        value={updatedUserDetail.po_value}
                        onChange={handleInputChange}
                        />
                    </div>

                    <div className="text_input">
                        <span>Supplier Quote</span>
                        <input
                        type="text"
                        name="supplier_quote"
                        value={updatedUserDetail.supplier_quote}
                        onChange={handleInputChange}
                        />
                    </div>
                    <div className="text_input">
                        <span>Monthly Revenue</span>

                        <select
                        name="monthly_revenue"
                        value={updatedUserDetail.monthly_revenue}
                        onChange={handleInputChange}
                        >
                        <option value="0">0 - 50k</option>
                        <option value="1">50k - 100k</option>
                        <option value="2">100k - 150k</option>
                        <option value="3">150k +</option>
                        </select>
                    </div>
                        
                    <div className="save_button_div">
                        <button className="save_changes_button" type="button" onClick={handleUpdateProfile}>
                        Save Changes
                        </button>
                    </div> */}
                    </div>
                </div>
                </div>
                </div>

        <Footer />
    </div>
    );
};

export default LenderPartner;
