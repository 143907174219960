import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AppContext from '../utils/AppContext';
import { useLocation } from 'react-router-dom';
import LenderDetails from './LenderDetails';
import StaticNavBar from '../StaticNavBar/StaticNavBar';
import DashboardNavbar from '../Dashboard_Navbar/Dashboard_Navbar';
import Navbar from '../Navbar/Navbar';
import "./CreateLender.css";

function CreateLender() {

    const location = useLocation();
    let { lender } = location.state;

    let {backendRoot} = useContext(AppContext);
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        telephone: '',
        name: '',
        contact_person: '',
        address: '',
        partnership_types: '',
    });

    useEffect(() => {
        if (lender) {
            setFormData({
                username: '', // Add logic to generate username if needed
                email: lender.email || '',
                telephone: lender.telephone || '',
                name: lender.name || '',
                contact_person: lender.contact_person || '',
                address: lender.address || '',
                partnership_types: lender.partnership_types || '',
                // Set other fields accordingly
            });
        }
        console.log("formdata", formData);
    }, [lender]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const accessToken = JSON.parse(localStorage.getItem('authTokens')).access;
        try {
            const response = await axios.post(`${backendRoot}/add/lender/`,
            [formData],
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            console.log(response.data);
        } catch (error) {
            console.error('Error creating user:', error);
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <div className="Homepage_master_div">
            <StaticNavBar />
            <div className="navbar-for-dashboard">
                <Navbar showApplyNowButton={false} isDashboard={true} />
            </div>
            <DashboardNavbar />
            <div className="dashboard_master_div">
                <div className="admin-dashboard">
                    <div className="form-container">
                        <h1>Create User</h1>
                        <form onSubmit={handleSubmit}>
                            <div className='form-group-master'>
                            <div className="form-group">
                                <label>Username</label>
                                <input type="text" name="username" onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <input type="email" name="email" value={formData.email} onChange={handleChange} />
                            </div>
                            </div>
                            <div className='form-group-master'>
                            <div className="form-group">
                                <label>Telephone</label>
                                <input type="tel" name="telephone" value={formData.telephone} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>Name</label>
                                <input type="text" name="name" value={formData.name} onChange={handleChange} />
                            </div>
                            </div>
                            <div className='form-group-master'>
                            <div className="form-group">
                                <label>Contact Person</label>
                                <input type="text" name="contact_person" value={formData.contact_person} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>Address</label>
                                <input type="text" name="address" value={formData.address} onChange={handleChange} />
                            </div>
                            </div>
                            <div className='form-group-master'>
                                <div className="form-group">
                                    <label>Partnership Types</label>
                                    <input type="text" name="partnership_types" value={formData.partnership_types} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="form-group">
                                <button type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateLender;
